import { useEffect, useState, useRef } from "react";
import {
  Alert,
  Button,
  Divider,
  Breadcrumb,
  Table,
  Tag,
  Switch,
  Tooltip,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import {
  UpOutlined,
  DownOutlined,
  HomeOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { PiApproximateEquals } from "react-icons/pi";
// import Loader from "../../components/Loader/Loader";
import Bitcoin from "../../assets/Bitcoin.svg";
import Litetcoin from "../../assets/ltc.png";
import WalletModal from "../../components/WalletModal/WalletModal";
import { bearerInstance } from "../../utils/API";
import "./Wallet.scss";
import moment from "moment";
import TxModal from "../../components/TxModal/TxModal";
import { currencyFormat } from "../../utils/formatSourceText";
import { useSelector, useDispatch } from "react-redux";
import { bearerInstanceWithToken } from "../../utils/API";
import { FaRegQuestionCircle } from "react-icons/fa";
import { setDefaultCoin as SetPersistCoin } from "../../redux/coin/coin.actions";

const Wallet = () => {
  const [loading, setLoading] = useState(false);
  const [loadingTx, setLoadingTx] = useState(false);
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [selectCoin, setSelectCoin] = useState("btc");
  const [reload, setReload] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [txModal, setTxModal] = useState(false);
  const [txData, setTxData] = useState(null);
  const [send, setSend] = useState(false);
  const [sent, setSent] = useState(null);
  const [btcPrice, setBtcPrice] = useState("");
  const [data, setData] = useState();
  const [view, setView] = useState(6);
  const [userBalance, setUserBalance] = useState();
  const reloadRef = useRef(null);
  const user = useSelector((state) => state?.user?.userData);
  // const coinD = useSelector((state) => state.coin.defaultCoin);
  const [chargeCoin, setChargeCoin] = useState(null);
  const [coin, setCoin] = useState("LTC");
  const [coinPrice, setCoinPrice] = useState(0);
  const [defaultCoin, setDefaultCoin] = useState("");
  const [defaultTempCoin, setDefaultTempCoin] = useState("");
  // const [defaultNetwork, setDefaultNetwork] = useState(userBalance?.network);
  // const [balance, setBalance] = useState(0);
  const [open, setOpen] = useState(false);
  // const [checkDefaultCoin, setCheckDefaultCoin] = useState("");
  const dispatch = useDispatch();
  const handleCoinChange = (coin) => {
    setCoin(coin);
    dispatch(SetPersistCoin(coin));
  };

  console.log(data);

  const columns = [
    {
      title: "transaction",
      dataIndex: "transaction",
      key: "transaction",
      render: (_, record) => (
        <div
          className="wallet-table-transaction"
          // style={{ opacity: record.confirmations === 0 ? '0.5' : '1' }}
        >
          {record.type === "receive" ? (
            <DownOutlined style={{ color: "#999", marginRight: "5px" }} />
          ) : (
            <UpOutlined style={{ color: "#999", marginRight: "5px" }} />
          )}
          <div
            style={{
              height: "16px",
              width: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "5px",
            }}
          >
            <img
              alt="bitcoin"
              src={coin.toLowerCase() === "btc" ? Bitcoin : Litetcoin}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
          <div>
            <p style={{ marginBottom: 0, fontSize: "13px" }}>
              {/* {record.mode === "dispute" ? (
                <span style={{ color: "#14a014" }}>
                  {record.type === "receive" ? "escrow award" : "escrow loss"}
                </span>
              ) : record.mode === " commission" ? (
                <span style={{ color: "#14a014" }}>
                  
                  commission
                </span>
              ) : (
                <>
                  <span style={{ color: "#14a014" }}>
                    {record.type === "receive" ? "received" : "commttission"}
                  </span>
                </>
              )}
              <br />
              {record.mode === "internal" ? "internal **" : ""} */}

              <span style={{ color: "#14a014" }}>
                {record.type === "send" && record.mode === "return" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>sent</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      return
                    </span>
                  </>
                ) : record.type === "receive" && record.mode === "return" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>receive</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      return
                    </span>
                  </>
                ) : record.type === "send" && record.mode === "internal" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>sent</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      internal
                    </span>
                  </>
                ) : record.type === "receive" && record.mode === "internal" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>received</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      internal
                    </span>
                  </>
                ) : record.type === "send" && record.mode === "external" ? (
                  "sent"
                ) : record.type === "receive" && record.mode === "external" ? (
                  "received"
                ) : record.type === "send" && record.mode === "dispute" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>sent</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      escrow
                    </span>
                  </>
                ) : record.type === "receive" && record.mode === "dispute" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>received</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      escrow
                    </span>
                  </>
                ) : record.type === "send" && record.mode === "commission" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>sent</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      commission
                    </span>
                  </>
                ) : record.type === "send" && record.mode === "return" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>sent</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      escrow
                    </span>
                  </>
                ) : record.type === "receive" && record.mode === "return" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>received</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      escrow
                    </span>
                  </>
                ) : record.type === "receive" && record.mode === "escrow" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>received</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      escrow
                    </span>
                  </>
                ) : record.type === "send" && record.mode === "escrow" ? (
                  <>
                    <span style={{ lineHeight: "0px" }}>sent</span>
                    <br />
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "gray",
                        marginBottom: "10px",
                      }}
                    >
                      escrow
                    </span>
                  </>
                ) : (
                  "pending"
                )}
              </span>
            </p>
            <p
              style={{
                marginBottom: "10px",
                fontSize: "11px",
                marginTop: "3px",
              }}
            >
              {moment(record.tx_created_at).format("LL")}
              <br />
              {moment(record.tx_created_at).format("LT")}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Tag
          className="clicker"
          style={{
            fontSize: "13px",
            marginBottom: 0,
            marginRight: 0,
            cursor: "pointer",
            // opacity: record.confirmations === 0 ? '0.5' : '1',
          }}
          color={
            record.confirmations === 0
              ? "default"
              : record.confirmations < 3 && record.confirmations > 0
              ? "yellow"
              : "green"
          }
        >
          {record.confirmations === 0
            ? "detected"
            : record.confirmations < 3
            ? "pending"
            : record.confirmations >= 3
            ? "successful"
            : "pending"}
        </Tag>
        // </Tooltip>
      ),
    },
    {
      title: "amount",
      key: "amount",
      dataIndex: "amount",
      render: (text, record) => (
        <>
          <p
            style={{
              marginBottom: 0,
              fontSize: "13px",
              textAlign: "right",
              // opacity: record.confirmations === 0 ? '0.5' : '1',
            }}
          >
            {record.type === "receive" ? "+" : "-"}
            {Number(record.final_amount).toFixed(6)} {coin}
          </p>
          <p
            style={{
              marginBottom: 0,
              fontSize: "11px",
              textAlign: "right",
              // opacity: record.confirmations === 0 ? '0.5' : '1',
            }}
          >
            {record.type === "receive" ? "+" : "-"}
            {currencyFormat(record.final_amount_usd)} USD
          </p>
        </>
      ),
    },
  ];

  const Network = "MAINNET";

  const fetchData = () => {
    setReload(true);
    setLoadingWallet(true);

    bearerInstanceWithToken(user?.token)
      .get(
        `/wallet_block?view_wallet=1&network=${Network}&chain=${coin.toUpperCase()}`
      )
      .then((res) => {
        setUserBalance(res.data.wallet_data[0]);
        setLoadingWallet(false);

        const filterDefault = res.data.wallet_data.filter((item) => {
          return item.is_active !== 0;
        });

        if (filterDefault.length === 0) {
          setDefaultCoin("");
        } else {
          setChargeCoin(filterDefault[0]?.network.replace("TEST", ""));
          setDefaultCoin(filterDefault[0]?.network.replace("TEST", ""));
        }

        return bearerInstance.get("/wallet_block?prices=1");
      })
      .then((res) => {
        // setBtcPrice(res.data.message.USD["15m"]);

        setLoading(false);
        setReload(false);
      })
      .catch((err) => {
        setLoadingWallet(false);
        // console.log("something went wrong");
      });
  };

  const loadTransactions = () => {
    setLoadingTx(true);

    bearerInstanceWithToken(user?.token)
      .get(
        `/wallet_block?list_transactions=1&network=${Network}&chain=${coin.toUpperCase()}`
      )
      .then((res) => {
        const data = res.data.transaction_data
          .map((cur, i) => {
            return {
              key: i,
              ...cur,
            };
          })
          .sort((a, b) => moment(b.tx_created_at) - moment(a.tx_created_at));
        setData(data);
        setLoadingTx(false);
      })
      .catch((err) => {
        setLoadingTx(false);
        // console.log("something went wrong");
      })
      .finally(() => {
        setLoadingTx(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coin]);

  useEffect(() => {
    loadTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coin]);

  // useEffect(() => {
  //   const fetchPrice = () => {
  //     bearerInstance.get("/wallet_block?prices=1").then((res) => {
  //       setBtcPrice(res.data.message.USD["15m"]);
  //     });
  //   };

  //   // Interval to fetch price every 60 seconds
  //   const intervalId = setInterval(fetchPrice, 15000);

  //   // Initial fetch
  //   fetchPrice();

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const handleReload = () => {
    fetchData();
    loadTransactions();
  };

  const handleChange = (value) => {
    setSelectCoin(value);
  };

  useEffect(() => {
    const fetchPrice = () => {
      bearerInstance
        .get(
          `/wallet_block?${
            coin.toLowerCase() === "btc" ? "from_btc" : "from_ltc"
          }=1&currency=usd&amount=1&network=${
            coin.toLowerCase() === "btc" ? "BTC" : "LTC"
          }`
        )
        .then((res) => {
          setCoinPrice(res.data);
          setBtcPrice(res.data);
        })
        .catch((err) => {});
    };

    // const fetchBalance = () => {
    //   bearerInstanceWithToken(user.token)
    //     .get(`/wallet_block?view_wallet=1&network=${coin.toUpperCase()}TEST`)
    //     .then((res) => {
    //       console.log(res);
    //       setCoinPrice(res.data?.wallet_data);
    //     });
    // };

    // fetchBalance();
    fetchPrice();
  }, [coin]);

  const listCoin = [
    { coinName: "Bitcoin", alt: "BTC", img: Bitcoin },
    { coinName: "Litecoin", alt: "LTC", img: Litetcoin },
  ];

  const handleSetDefaultCoin = () => {
    setLoading(true);

    let formData = new FormData();
    formData.append("set_active_wallet", 1);
    formData.append("network", `${Network}`);
    formData.append("chain", `${defaultTempCoin}`);

    bearerInstanceWithToken(user?.token)
      .post(`/wallet_block`, formData)
      .then((res) => {
        setCoinPrice(res.data.message);
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    await setDefaultCoin(defaultTempCoin);
    await setChargeCoin(defaultTempCoin);

    setTimeout(() => {
      handleSetDefaultCoin();
    }, 500);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const loadingData = loadingTx || loadingWallet || loading;

  return (
    <>
      {walletModal && (
        <WalletModal
          coin={coin}
          open={walletModal}
          send={send}
          sent={(val) => {
            setSent(val);
          }}
          close={() => setWalletModal(false)}
          btcPrice={+btcPrice}
          curBal={+userBalance?.balance + Number(userBalance?.bonus)}
          activeAddress={userBalance?.active_addresses}
          handleReload={handleReload}
          fetchData={fetchData}
        />
      )}

      {txModal && txData && (
        <TxModal
          open={txModal}
          close={() => setTxModal(false)}
          data={txData}
          coin={coin}
        />
      )}

      {/*  */}
      <Modal
        title="confirm default wallet"
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          are you sure, you want to make {coin} your default wallet? your
          default wallet will be used to fund trade escrows
        </p>
      </Modal>

      <div className="wallet">
        <div className="wallet-wrapper">
          {
            // !loading &&

            true && (
              <>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/">
                      <HomeOutlined />
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>wallet</Breadcrumb.Item>
                </Breadcrumb>

                <Alert
                  message="important notice"
                  description={
                    <>
                      <p className="notice-text">
                        these wallets are for funding trade escrows. as a
                        vendor, ensure you have enough BTC or LTC for the trade
                        value.
                      </p>
                      <p className="notice-text">
                        the default wallet is the wallet which will be used for
                        funding trade escrows. ensure you always select a
                        default wallet with an adequate balance so you can
                        accept new trade requests.{" "}
                      </p>
                      <p className="notice-text">
                        {" "}
                        while the security of your assets are assured, we do not
                        recommend using it (or any other centralised exchange
                        for that matter) for large or long-term holdings. use
                        self-custody for that. only keep what's needed for
                        trades here.
                      </p>
                      <p className="notice-text">
                        {" "}
                        litecoin has extremely low fees and fast confirmation.
                        can also be easily spot traded on any large exchange
                        like Binance. we encourage litecoin deposits for
                        convenience, but you can deposit into whatever wallet
                        you wish.
                      </p>
                    </>
                  }
                  type="info"
                  style={{ marginBottom: "20px" }}
                  closable
                  showIcon
                />

                <p className="">select coin</p>

                {/* select coin tab */}
                <div className="tab-box">
                  <div
                    className="coin-tab"
                    onClick={() => handleCoinChange("LTC")}
                    style={{
                      background: coin === "LTC" ? "#14a014" : "#9995",
                      color: coin === "LTC" ? "#fff" : "#000",
                    }}
                  >
                    <img className="coin-img" src={Litetcoin} alt="" /> Litecoin
                  </div>

                  <div
                    className="coin-tab"
                    onClick={() => handleCoinChange("BTC")}
                    style={{
                      background: coin === "BTC" ? "#14a014" : "#9995",
                      color: coin === "BTC" ? "#fff" : "#000",
                    }}
                  >
                    <img className="coin-img" src={Bitcoin} alt="" /> Bitcoin
                  </div>
                </div>

                {loadingData && (
                  <div className="loader-box">
                    <div className="loader"></div>
                  </div>
                )}

                {sent && (
                  <Alert
                    message={sent.message}
                    className={`wallet-alert ${
                      sent.type === "error" ? "wallet-alert-err" : ""
                    }`}
                    description={sent.description}
                    type={sent.type}
                    style={{ marginBottom: "20px", marginTop: "15px" }}
                    showIcon
                    closable
                  />
                )}

                {/* bitcoin price in usd */}
                <div className="wallet-price">
                  {coin.toLowerCase() === "btc" ? (
                    <div>
                      <div style={{ marginRight: "5px" }}>
                        <img alt="bitcoin" src={Bitcoin} className="coin-img" />
                      </div>
                      <p className="wallet-p">Bitcoin</p>
                    </div>
                  ) : (
                    <div>
                      <div style={{ marginRight: "5px" }}>
                        <img
                          alt="bitcoin"
                          src={Litetcoin}
                          className="coin-img"
                        />
                      </div>
                      <p className="wallet-p">Litecoin</p>
                    </div>
                  )}

                  <div>
                    <p className="wallet-p" ref={reloadRef}>
                      <SyncOutlined
                        spin={reload}
                        onClick={handleReload}
                        style={{
                          color: "#ed1450",
                          marginRight: "12px",
                        }}
                      />
                      price: {new Intl.NumberFormat("en-us").format(coinPrice)}{" "}
                      USD
                    </p>
                  </div>
                </div>

                {/* <Divider style={{ fontSize: "14px" }}>
                  set active wallet
                </Divider> */}

                {/* <div className="default-box">
                 
                </div> */}

                <Divider style={{ fontSize: "14px" }}>current balance</Divider>

                <div className="wallet-switch">
                  {listCoin.map((item, i, arr) => {
                    return (
                      <>
                        {coin.toLowerCase() === item.alt.toLowerCase() ? (
                          <div className="default-cont">
                            <div className="default-switcht">
                              <div className="">
                                <Switch
                                  checked={
                                    defaultCoin === chargeCoin ? true : false
                                  }
                                  onClick={() => {
                                    if (chargeCoin === defaultCoin) {
                                      return;
                                    } else {
                                      setDefaultTempCoin(item.alt);
                                      showModal();
                                    }
                                  }}
                                />
                              </div>

                              <div className="switch-text-box">
                                <p className="switch-text" onClick={showModal}>
                                  {/* {defaultCoin === chargeCoin
                                    ? "default"
                                    : "make default"} */}
                                  set as default
                                </p>

                                <Tooltip
                                  title={
                                    <>
                                      <p>
                                        by checking this box you are indicating
                                        that you want your default wallet on
                                        Lily to be {coin}{" "}
                                      </p>
                                      <p>
                                        as a vendor, your default wallet will be
                                        used to fund trade escrows.
                                      </p>
                                    </>
                                  }
                                >
                                  <FaRegQuestionCircle color="#14a014" />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </div>

                <Alert
                  message=""
                  description={
                    <>
                      <p className="notice-text">
                        note that the bonus balance can be traded with other
                        pairs and used to fund the escrow, but cannot be
                        withdrawn.
                      </p>
                      <p className="notice-text">
                        condition to withdraw bonus balance is to complete at
                        least 10 successful trades
                      </p>
                    </>
                  }
                  type="info"
                  style={{ marginBottom: "20px" }}
                  closable
                  showIcon
                />

                {/*wallet + send and receive bitcoin */}
                <div className="wallet-coin">
                  <div className="default-box">
                    <div className="">
                      <h2
                        className={
                          userBalance?.balance < 0
                            ? "header-color-negative"
                            : "header-color-positive"
                        }
                      >
                        {Number(userBalance?.balance).toFixed(6)} {coin}
                        <span className="wallet-bal-usd">
                          {" "}
                          <PiApproximateEquals />{" "}
                          {new Intl.NumberFormat("en-us").format(
                            coin.toLowerCase() === "btc"
                              ? userBalance?.btc_balance_usd
                              : userBalance?.ltc_balance_usd
                          )}{" "}
                          USD
                        </span>
                      </h2>

                      <p
                        className="wallet-bal-bon"
                        style={{ marginBottom: "20px", marginTop: "-5px" }}
                      >
                        bonus {Number(userBalance?.bonus).toFixed(6)} {coin}
                        <span className="wallet-bal-bon-usd">
                          {" "}
                          <PiApproximateEquals />{" "}
                          {/* {Number(
                            new Intl.NumberFormat("en-us").format(
                              coin.toLowerCase() === "btc"
                                ? userBalance?.btc_balance_bonus_usd
                                : userBalance?.ltc_balance_bonus_usd
                            )
                          ).toFixed(2)}{" "} */}
                          {Number(
                            coin.toLowerCase() === "btc"
                              ? userBalance?.btc_balance_bonus_usd ?? 0
                              : userBalance?.ltc_balance_bonus_usd ?? 0
                          ).toFixed(2)}{" "}
                          USD
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="wallet-coin-btn">
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        setSent(null);
                        setWalletModal(true);
                        setSend(true);
                      }}
                    >
                      send{" "}
                      {coin.toLowerCase() === "ltc" ? "Litecoin" : "Bitcoin"}
                    </Button>

                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        setWalletModal(true);
                        setSend(false);
                      }}
                    >
                      receive{" "}
                      {coin.toLowerCase() === "ltc" ? "Litecoin" : "Bitcoin"}
                    </Button>
                  </div>

                  <div className="wallet-coin-btn">
                    <Button className="wallet-coin-btn-1" block>
                      <UpOutlined />
                      buy{" "}
                      {coin.toLowerCase() === "ltc" ? "Litecoin" : "Bitcoin"}
                    </Button>

                    <Button className="wallet-coin-btn-1" block>
                      <DownOutlined />
                      sell{" "}
                      {coin.toLowerCase() === "ltc" ? "Litecoin" : "Bitcoin"}
                    </Button>
                  </div>
                </div>

                <Divider style={{ fontSize: "14px" }}>recent activity</Divider>

                <Table
                  className="wallet-table"
                  pagination={false}
                  columns={columns.map((cur, i) => ({
                    ...cur,
                    onCell: (data, index) => {
                      return {
                        onClick: (event) => {
                          // if (event.target.className.includes('clicker')) {
                          setTxData(data);
                          setTxModal(true);
                          // }
                        },
                      };
                    },
                  }))}
                  dataSource={data?.slice(0, view)}
                  loading={loadingTx}
                />

                <Button
                  onClick={() => {
                    if (data.length - view > 6) setView(view + 6);
                    else setView(data?.length);
                  }}
                  type="text"
                  style={{
                    color: "#ed1450",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "8px",
                  }}
                >
                  load more
                </Button>
              </>
            )
          }
        </div>
      </div>
    </>
  );
};

export default Wallet;
